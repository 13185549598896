<template>
  <div>
    <el-page-header @back="dynamic" content="密码修改" style="margin-bottom: 15px;">
    </el-page-header>
    <el-card class="box-card" style="margin-top: 50px">
      <el-form :model="ruleForm" :rules="rules" label-width="100px">
        <el-form-item label="用户名：">
          <div>{{ ruleForm.userName.toUpperCase() }}</div>
        </el-form-item>
        <el-form-item label="旧密码：">
          <el-input type="password" v-model="ruleForm.oldPass"></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="pass">
          <el-input type="password" v-model="ruleForm.pass"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editPassWd(ruleForm)">修改密码</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "editPassWd",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        userName: window.localStorage.getItem('UserName'),
        oldPass: '',
        pass: '',
        checkPass: ''
      },
      rules: {
        pass: [
          {validator: validatePass, trigger: 'blur'}
        ],
        checkPass: [
          {validator: validatePass2, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    editPassWd(ruleForm) {
      this.$http.post('/api/user/editPassWord',ruleForm).then((res)=>{
        if (res.data.status) {
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.ruleForm.pass = null;
          this.ruleForm.checkPass = null;
          this.ruleForm.oldPass =null;
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.box-card {
  width: 480px;
  margin: auto;

  padding-top: 10px;
}
</style>